:root {
    --primary-color: #007bff;
    --secondary-color: #007bff;
    --text-color: #333;
    --white-color: #ffffff;
    --hover-opacity: 0.8;
    --dashboard-bg: #ffffff;

    --dropdown-color: white;
    --dropdown-border: rgba(130, 130, 130, 0.685);
    --dropdown-text: black;
    --dropdown-focus: #007bff;
    --dropdown-radius: 2px;

    --card-color: #3D3C3C;
    --card-standard: #e7e7e7;
    --card-highlight: #d6d6d6;
    --card-text: rgb(0, 0, 0);
    --card-border: ;

    --button-color: #007bff;
    --button-hover-color: #0056b3;
    --button-ready-color: #00bd19;
    
    --toggle-background-color: #938D8A;
    --toggle-dot-inactive-color: #ffffff;
    --toggle-dot-active-color: #107bdf;

    --table-header-background: #1565C0;
    --table-header-text: white;
    --table-header-border-color: black;
    --table-row-hover: #c1e1ec;
    --table-row-selected: #c1e1ec;
    --table-row-dark: #bfbebe;
    --table-row-light: #e5e5e5;
    --table-border-color: var(--dropdown-border);
    
    --searchbox-background-color: rgba(245, 245, 245, 0.62);
    --header-button-color: #0144a2;
  }
@import './variables.css';
/* Common styles */
*, *::before, *::after {
  box-sizing: border-box;
}

body, html {
    height: 100%;
    margin: 0;
    font-family: "proxima-nova", sans-serif;
    background-color: #ffffff;
    color: #221F1B;
  }

  hr {
    border: 5;
    border-top: 1px solid currentColor;
    margin: 2rem 0; /* Adjust the margin as needed */

  }  

  .dev-border-dashed {
    border-color: black;
    border-style:dashed;
  }

  .dev-border-solid {
    border-color: black;
    border-style:solid;
  }

  .button {
    background-color: #0144a2;
    color: white;
    border: none;
    display: inline-block; /* Prevents full-width stretching */
    width: fit-content; /* Ensures the button only takes as much space as needed */
  }
  
  /* Login Page Styles ---------------------------------------------------------------------------*/
    /*--------------------------------------------------------------------------------------------*/
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 75px);
    width: 100vw; /* Ensure it takes the full width */
    background: url('/src/assets/images/loginbg.webp') no-repeat center center/cover;
  }
  
  .login-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  
  .login-container h1 {
    margin-bottom: 1.5rem;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container label {
    margin-bottom: 0.5rem;
    text-align: left;
  }
  
  .login-container input {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-container button {
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }
  
  .login-container p {
    margin-top: 1rem;
  }
  
  .login-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .login-container a:hover {
    text-decoration: underline;
  }

  .login-footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    color: #fff;
  }
  
  .login-footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
  }
  
  .login-footer a:hover {
    text-decoration: underline;
  }

  /*-------------------Hero Containers ----------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------*/
  .hero-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    height: auto;
    margin-left: 0px; /* Margin off the left side */
    text-align: left;
    background-color: white; /* Light blue color, change to any color you prefer */
    /*background-image: url("your-image-url.jpg"); /* Optional: Add an image */
    background-size: cover; /* Make sure the image covers the entire section */
    background-position: center; /* Center the background image */
    /* border-style: dashed; */
    /* border-color: red; */
    width: calc(100%-2px);
    overflow: hidden;
  }

  .hero-container h1 {
    font-family: "canada-type-gibson", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 4rem;
    color: white;
    margin-top: 0;
    margin-bottom: 0.5rem;
    margin-left: 8rem;
  }

  .hero-container h2 {
    font-family: "canada-type-gibson", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 3.25rem;
    color:#FFC15E;
    margin-top: 0;
    margin-bottom: 0.5rem;
    margin-left: 9.5rem;
  }

  .hero-section {
    display: flex;
    flex-direction: row;
    background: linear-gradient(135deg, #3F8EFC 35%, #ffffff 100%);
    /*padding: 50px; /* Adjust padding as needed */
/*     border-style: dashed;
    border-color: green; */
   /*  aspect-ratio: 16 / 9; */
    max-height: calc(100vh - 75px);
    height: auto;
    width: calc(100% - 2px);
    overflow: hidden;
    padding:0;
  }

  /* Adjust for portrait mode (tablets, small screens) */
  @media (max-width: 1024px) {
  .hero-section {
    flex-direction: column; /* Stack elements vertically */
    min-height: 90vh; /* Ensures full screen coverage */
    justify-content: center;
    padding-bottom: 0;
    }
  }

  .hero-section p {
    font-family: "proxima-nova", sans-serif;
    font-size: 22px;    
    line-height: 1.1;     /* Set line height for readability */
    color: white;
/*     margin-bottom: 0px; */
    margin-top:0;
    margin-bottom: 1rem;
    margin-left: 8rem;
    text-align: justify;
    /* border-style: dashed; */
  }

  .hero-sub-sections {
    display: flex;          /* Set to flex to align children in a row */
    flex-direction: row;    /* Ensure the children are aligned horizontally */
    gap: 0px;              /* Add space between the sections */
    width: 100%;            /* Ensure the container takes the full width */
  }
  
  .hero-sub-section-left {
/*     border-style: solid;
    border-color: green; */
    width: 60%;
    margin-right: 0rem;

    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:0;
  }

  .hero-sub-section-right {
    display: flex;
    width: 40%;
    align-items: flex-end;
    justify-content: center;
  }

  .hero-sub-section-right img {
    max-width: 100%;  /* Ensures the image does not exceed its container */
    max-height: 100%; /* Ensures the image scales properly */
    height: auto;     /* Maintain aspect ratio */
    width: auto;      /* Allow natural width scaling */
    object-fit: contain; /* Ensures the entire image fits within the container */
    display: block;   /* Removes unwanted extra space below the image */
  }
  

/*   .hero-content {
    border-style: dashed;
    border-color: blue;
    margin-top: 0;
    padding: 0;
  } */

  .get-started-button {
    background-color: #0144A2;
    color: white;
    border: none;
    text-decoration: none;
    padding: 4px 16px;
    border-radius: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #0144A2;
  }

  .get-started-button-container {
/*     border-style: dashed;
    border-color: blue; */
    height: 3rem;
    align-content: center;
    margin-left: 8rem;
  }
  
  .full-width {
    width: 100vw; /* Full viewport width to extend across the screen */
    margin-left: calc(-50vw + 50%); /* Remove any gaps or padding on the left */
  }
  
  .full-height {
    height: calc(100vh - 60px); /* Subtract the height of your header (e.g., 60px) */
  }

  .villain-section {
    aspect-ratio: 16 / 9;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    height: auto;
/*     border-style: dashed;
    border-color: purple; */
    width: calc(100% - 2px);
    overflow: hidden;
    align-items: center;
  }

  .villain-section h3 {
    font-size: 3.5rem;
    color: var(--secondary-color);
    font-family: "proxima-nova", sans-serif;
    font-weight: 800;
    margin-top:2rem;
    margin-bottom: 1rem;
  }

  .villain-deck {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  }

  .villain-cards {
/*     border-style:dashed;
    border-color: red; */
    padding: 2rem;
    width: 425px;
    text-align: center;
    }

  .villain-cards h4 {
    font-family: "proxima-nova", sans-serif;
    color: var(--secondary-color);
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .villain-cards h5 {
    font-family: "proxima-nova", sans-serif;
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 1.5rem;
  }

  .villain-cards img {
    width: 8rem;
  }

  .villain-deck-dark {
    display: flex;
    flex-direction: row;
    width: 87%;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: #221F1B;
    padding: 3rem;
    gap: 15%;
  }

  .villain-deck-dark a {
    text-decoration: none;
    color: inherit;
}

  .villain-cards-dark {
    padding: 2rem;
    width: 425px;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.606);
    transition: background-color 0.3s ease, color 0.3s ease;
    color: inherit;
  }

  .villain-cards-dark:hover {
    background-color: #6261617a;
    color: #221F1B;
    border-color: #FFC15E;
  }

  .villain-deck-dark h4{
    font-family: "proxima-nova", sans-serif;
    color: #FFC15E;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .villain-cards-dark h5 {
    font-family: "proxima-nova", sans-serif;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .villain-cards-dark img {
    width: 8rem;
  }

  .villain-go-button {
    font-family: "proxima-nova", sans-serif;
    background-color: #FFC15E;
    color: #221F1B;
    border: none;
    text-decoration: none;
    padding: 4px 16px;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid #FFC15E;
    margin-top: 0;
  }

  .villain-go-button-container {
/*     border-style: dashed;
    border-color: blue; */
    margin-top: 0;
    height: 3rem;
    align-content: center;
    margin-left: 8rem;
  }

  .wiki-section {
    aspect-ratio: 16 / 9;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    height: auto;
/*     border-style: dashed;
    border-color: purple; */
    width: calc(100% - 2px);
    overflow: hidden;
    align-items: center;
    background-color: #ffffff;
  }
 
  /* Dashboard and Admin Page Styles -----------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  .dashboard-container, .admin-container {
    padding: 2rem;
    margin-left: 20px; /* Margin off the left side */
    text-align: left;
  }
  
  .dashboard-container h1, .admin-container h1 {
    margin-bottom: 1.5rem;
  }
  
  .dashboard-container ul, .admin-container ul {
    list-style: none;
    padding: 0;
  }
  
  .dashboard-container li, .admin-container li {
    margin-bottom: 1rem;
  }
  
    /* Home Page Styles ------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  .home-container {
    padding: 2rem;
    margin-left: 20px;
    text-align: left;
  }
  
  .home-container h1 {
    margin-bottom: 1.5rem;
  }
  
  .home-container p {
    margin-bottom: 1.5rem;
  }
  
  .home-container button {
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .home-container button:hover {
    background-color: #0056b3;
  }

/* Side Bar Styles ------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------*/
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .menu-btn {
    position: fixed;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1000;
  }
  
  .main-content {
    flex-grow: 1;
    margin-left: 80px;
    transition: margin-left 0.2s ease-in-out;
  }

  .main-content:after{
    content:"";
    display:block;
/*     height: 75px; */
  }
  
  .sidebar.mobile ~ .main-content {
    margin-left: 200px;
  }
  
  .sidebar.open + .main-content {
    margin-left: 0;
  }

  /* Spinner Style ------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------*/

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #038bfa; /* Green color for the spinner */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-top: 15px; /* Optional: Add some space above the spinner */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /*--------------------------------Toggle Switch----------------------------------------*/
  /*-------------------------------------------------------------------------------------*/
  .toggle-container {
  display:flex;
  align-items: center;
  text-align: center;
  height: 30px;
/*   border-style: dashed;
  border-color: greenyellow; */
  }
  
  /* Container of the toggle switch */
.toggle-switch {
  width: 40px;
  height: 12px;
  background-color: var(--toggle-background-color); /* Dark grey background */
  border-radius: 15px; /* Fully rounded to make it pill-shaped */
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* The circle/handle of the toggle switch */
.toggle-thumb {
  width: 22px;
  height: 22px;
  background-color: var(--toggle-dot-inactive-color);
  border: 2px solid var(--toggle-dot-active-color); /* Added white border */
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -1px;
  transition: left 0.3s ease;
}

/* Class added when toggle is active/on */
/* .toggle-switch.on {
  background-color: #00C4AC; /
} */

.toggle-switch.on .toggle-thumb {
  left: 22px; /* Move the toggle thumb to the right side */
  background-color: var(--toggle-dot-active-color);
}

/* .input {
  margin-top: 4px;
  margin-bottom: 4px;
} */

.input-disabled {
  background-color: #e0e0e0 !important; /* Light gray background */
  cursor: not-allowed; /* Not-allowed cursor */
}

/* Flex container for aligning input, label, and icon */
.flex-container {
  display: flex;
  align-items: center; /* Align all items vertically in the center */
  gap: 5px; /* Adjust spacing between the input, label, and icon as needed */
}

/* Style for the info icon */
.info-icon {
  cursor: pointer;
  color: #f11212; /* Customize color as desired */
}

/*-------------------------------------Check Mark Icons -----------------------*/
/*-----------------------------------------------------------------------------*/
/* Checkmark for Unit Details */
.checkmark-icon {
  all: unset; /* Reset all styles */
  display: inline-block;
  width: 10px; /* Smaller size */
  height: 10px;
  border-radius: 50%;
  border: 2px solid;
  text-align: center;
  line-height: 14px; /* Adjust to center the checkmark */
  font-size: 10px; /* Smaller checkmark */
/*   margin-left: 10px; */
  vertical-align: middle; /* Align with text */
/*  transform: translateY(-3px); /* Adjust the value to bump it up vertically */
  transition: all 0.3s ease;
}

/* Incomplete checkmark (grayed out) */
.checkmark-icon.incomplete {
  background-color: red;
  color: white; /* For potential inner text, e.g., X */
}

.checkmark-icon.incomplete::after {
  content: '\274C'; /* Checkmark symbol */
  color: white;
  display: block;
  text-align: center;
  line-height: 20px;
  border-color: red;
}

/* Complete checkmark (blue with white background) */
.checkmark-icon.complete::after {
  content: '\2713'; /* Checkmark symbol */
  color: white;
  display: block;
  text-align: center;
  line-height: 20px;
  border-color: #3F8EFC; /* Blue border */
}
/*
/* Complete checkmark (blue with white background) */
.checkmark-icon.complete {
  font-size: 12px;   /* Increase the font size for a larger checkmark */
  font-weight: bold; /* Make the checkmark bold */
  color: white; 
  border-color: #3F8EFC; 
  background-color: #3F8EFC; 
}

/*-------------------------------------  MODAL CONTENT ------------------------*/
/*-----------------------------------------------------------------------------*/

.modal {
  position: fixed; /* Ensures it stays over everything */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensures it is above the header */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1001; /* Ensure content is above the modal backdrop */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adds spacing between buttons */
  margin-top: 15px; /* Adjusts spacing above buttons */
}

/* Increase specificity for modal styles */
.ASH-dashboard-container .modal {
  display: block; /* Adjust display as per the requirement (use 'none' for hidden initially) */
  position: fixed;
  z-index: 1000; /* Ensure it stays above other elements */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.805); /* Semi-transparent black background */
}

/* Modal content styles */
.ASH-dashboard-container .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 5px; /* Rounded corners */
  color: #333; /* Text color */
}

/* Close button styles */
.ASH-dashboard-container .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.ASH-dashboard-container .close:hover,
.ASH-dashboard-container .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/*-------------------------------------  TABLE STYLES ------------------------*/
/*-----------------------------------------------------------------------------*/

/* Ensure hover effect is working */
.space-summary-table {
  width: 100%;
  border-collapse: collapse; /* Remove double borders */
  margin-top: 15px; /* Increase space above the table */
  background-color: #444; /* Darker background to contrast with row colors */
}

.space-summary-table th {
  background-color: var(--table-header-background); /* Dark blue for header background */
  color: var(--table-header-text); /* White text for contrast */
  font-weight: bold;
  border: 1px solid var(--table-header-border-color); /* Consistent border style, darker to improve readability */
  text-align: center; /* Center align the text */

}.space-summary-table tbody tr:hover {
  background-color: var(--table-row-hover) !important; /* A lighter blue for better hover visibility */
  cursor: pointer; /* Change the cursor to indicate interactivity */
}

/* Ensure selected state styling is applied correctly */
.space-summary-table tbody tr.selected {
  background-color: var(--table-row-selected) !important; /* Highlight selected rows */
}

.space-summary-table td {
  padding: 2px; /* Adjusted padding to reduce row height */
  border: 1px solid var(--table-border-color); /* Consistent border style, darker to improve readability */
  text-align: center; /* Center align the text */
}

.space-summary-table tbody tr:nth-child(odd) {
  background-color: var(--table-row-dark); /* Dark rows */
}

.space-summary-table tbody tr:nth-child(even) {
  background-color: var(--table-row-light); /* Light rows*/
}
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

/*------------------------- MESSAGE BAR STYLES ------------------------------------*/
/*--------------------------------------------------------------------------------*/

.message-bar {
  position: fixed;
  top: -10px; /* Start off-screen */
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 1000;
  display: block;
  transition: top 2s ease-in-out;
}

.message-bar.show {
  top: 0; /* Slide down when active */
}

.success {
  background-color: #4CAF50;
  color: white;
}
.error {
  background-color: #F44336;
  color: white;
}
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/




/*------------------------- ADMIN / MY-ACCOUNT PAGE STYLES -----------------------*/
/*--------------------------------------------------------------------------------*/
.admin-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 75px);
  padding-top: 60px;
  margin-left: 80px;
  gap: 2px;
}

.account-divider {
  border: none;
  height: 2px;
  background: linear-gradient(to right, #007bff, #0056b3); /* Gradient effect */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds slight shadow */
  width: 95%;
  margin: 0px;  
}
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/




/*------------------------- Privacy Policy PAGE STYLES ---------------------------*/
/*--------------------------------------------------------------------------------*/
.privacy-policy-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: left;
}
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/




  
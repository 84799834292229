@import './variables.css';

.footer-section {
    display:flex;
    width: calc(100% - 2px); 
    height: 75px;
    background-color: #0144A2;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding-left: 80px;
    margin-top:auto;
  }

  .footer-section p{
    color:white;
  }

  .footer-section .left-content {
    display: flex;
    gap: 2rem;
  }

  .footer-section .right-content {
    display: flex;
    gap: 1rem;
    padding-right: 3rem;
    font-size: 1.7rem;
    gap:2rem;
  }

  .footer-section a {
    color: white;
    text-decoration: none;
  }

  .footer-section a:hover {
      text-decoration: underline;
  }
@import './variables.css';

/* Dashboard Container */
.ASH-dashboard-container {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 65px);
    background-color: var(--dashboard-bg);
    padding-top: 60px;
    box-sizing: border-box;
    align-items: stretch;
}

/* Left Column */
.ASH-dashboard-left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 350px;
    padding: 0 0.5rem 0 1rem; /* Top, Right, Bottom, Left */
    gap: 1rem;
    background-color: var(--dashboard-bg);
    box-sizing: border-box;

}

/* Main Content Area */
.ASH-dashboard-main {
    display: flex;
    flex-direction: column;
    flex-grow:1;
    width: 75%;
    padding: 0 1rem 0 0.5rem; /* Top, Right, Bottom, Left */
    gap: 1rem;
    box-sizing: border-box;
    min-height: 100%;
}

/* Card Style */
.ASH-dashboard-card {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: var(--card-standard);
    color: var(--card-text);
    border-radius: 2px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    justify-content: left;   
    margin: 0; 
}

.ASH-dashboard-card:hover {
    background-color: var(--card-highlight);
}

.ASH-dashboard-card h5{
    margin: 0;
    font-size: 1.25rem;
}

.ASH-dashboard-card h1{
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 0.75;
    padding: 0;
    font-size: 3.0rem;
    color: var(--primary-color);
}

.floorplan, .space-summary {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}

    /*-------------------------DROP DOWN BOX -------------------------*/
    .dropdown-container {
        margin-top: 0px;
        margin-bottom: 1px;
    }
      
      .dropdown-container select {
        font-size: 12px;
        background-color: var(--dropdown-color);
        color: var(--dropdown-text);
        border: 1px solid var(--dropdown-border); /* Optional: Change the border color */
        border-radius: var(--dropdown-radius); /* Add rounded corners */
        outline: none;
        margin:0;
        height: 25px;
      }
    
      .dropdown-container select option {
        background-color: var(--dropdown-color); /* Dark background for the options */
        color: var(--dropdown-text); /* White text color */
      }

    /*-------------------------DROP DOWN BOX -------------------------*/


    /*-------------------------------Charge Inputs-----------------------------------------*/
    .charge-inputs-container {
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 0;
        box-sizing: border-box;
    
        display: flex;          /* Enables flexbox */
        align-items: center;    /* Vertically centers items */
        justify-content: start; /* Aligns items horizontally to the left */
        gap: 5px;              /* Optional: Adds spacing between child items */
    }

.charge-inputs-container input {
    margin-bottom: 0;
    height: 25px; /* Same height as the unit details number inputs */
    padding: 4px 8px; /* Consistent padding */
    font-size: 12px;
    border-radius: var(--dropdown-radius);
    border: 1px solid var(--dropdown-border);
    background-color: var(--dropdown-color);
    width: 100px; /* Same width as unit detail inputs */
    color:var(--dropdown-text);
    
    /* Remove spinner arrows (same as the Unit Details section) */
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Chrome, Safari, Edge */
  }

.charge-inputs-container input[type="number"]::-webkit-outer-spin-button,
.charge-inputs-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Remove in WebKit browsers */
  margin: 0;
}

.charge-inputs-container input:focus {
  outline: none;
  border-color: var(--dropdown-focus);
  border-width: 2px;
}

.space-actions {
    position: relative; /* Position the container relative to its parent */
    z-index: 20; /* Ensure buttons appear above other content */
  }
  
  .space-actions button {
    position: relative; /* Ensure the buttons respect the container's z-index */
    z-index: 20; /* Buttons must be above the header */
  }

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #0144a2;
  color: #C4BBB8;
  padding-top: 20px;
  transition: width 0.2s ease-in-out;
  z-index: 1000; /* Ensure the sidebar is above other elements */
  overflow: hidden; /* Hide any overflowing content during the transition */
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.sidebar.collapsed .close-btn {
  opacity: 0;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin-top: 50px;
}

.sidebar nav ul li {
  padding: 0px 0px;
}

/* Common styles for both links and the logout button */
.sidebar nav ul li a,
.sidebar nav ul li button.logout-btn {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  white-space: nowrap; /* Prevent text wrapping */
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 10px 20px;
  text-align: left;
  font-size: 16px; /* Match this to the font size of other links */
}

/* Hover effect for both links and the logout button */
.sidebar nav ul li a:hover,
.sidebar nav ul li button.logout-btn:hover {
  background-color: #3F8EFC; /* Highlight on hover */
}

.sidebar.collapsed nav ul li a,
.sidebar.collapsed nav ul li button.logout-btn {
  opacity: 0;
  transform: translateX(-30px); /* Adjust translate value for smoother effect */
}

.sidebar nav ul li a span,
.sidebar nav ul li button.logout-btn span {
  display: inline-block;
  margin-left: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.sidebar.collapsed nav ul li a span,
.sidebar.collapsed nav ul li button.logout-btn span {
  opacity: 0;
  transform: translateX(-30px);
}

.sidebar.collapsed nav ul li {
  justify-content: center; /* Center align icons when collapsed */
}

/* ----------------------------Divider line between sections -----------------------------------------*/
.sidebar-divider {
  border: 1px dashed #ccc; /* Adjust the color and thickness of the line */
  margin: 5px 10px; /* Adjust the vertical space above and below the hr */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* Hide the divider when the sidebar is collapsed, but keep the space */
.sidebar.collapsed .sidebar-divider {
  opacity: 0;
  visibility: hidden;
}

/* ------------------Main content styles--------------------------------------------------------- */
.app {
  display: flex;
}

.main-content {
  margin-left: 60px;
}

.sidebar-collapsed .main-content {
  margin-left: 60px;
}

/* --------------------------------------Project Buttons ---------------------------------------*/
.projects-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Adjust the padding to match other items */
  transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
}

.projects-item a {
  flex-grow: 1; /* Allow the link to take up the remaining space */
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.add-project-btn {
  background-color: #cfcfcf; /* Green background */
  border: none;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  font-size: 16px;
  margin-left: 10px; /* Space between the button and the text */
  margin-right: 10px; /* Space between the button and the text */
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

/* .projects-item:hover {
  background-color: #333; 
}

.projects-item:hover .add-project-btn {
  background-color: #05b2f7;
} */

.sidebar.collapsed .add-project-btn {
  display: none; /* Hide button when sidebar is collapsed */
}
.ashrae152-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: var(--dashboard-bg);
  }
  
  .ashrae152-header {
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .ashrae152-overview,
  .ashrae152-changes,
  .ashrae152-applications,
  .ashrae152-resources {
    margin: 20px 0;
  }
  
  .ashrae152-overview h2,
  .ashrae152-changes h2,
  .ashrae152-applications h2,
  .ashrae152-resources h2 {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .ashrae152-overview p,
  .ashrae152-changes ul,
  .ashrae152-applications p,
  .ashrae152-resources ul {
    margin-left: 20px;
  }
  
  .ashrae152-resources ul {
    list-style-type: square;
  }
  
  .ashrae152-footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .ashrae152-footer p {
    font-size: 14px;
    color: #7f8c8d;
  }
  
  .ashrae152-footer a {
    color: #2980b9;
    text-decoration: none;
  }

  h1 {
    font-family: proxima-nova, sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  body {
    font-family: "proxima-nova", sans-serif;
  }
  

  /*---------------------------Buttons------------------------------*/
  .button {
    background-color: var(--button-color); /* Default blue color */
    color: white;
    border: none;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .button:hover {
    background-color: var(--button-hover-color); /* Darker blue on hover */
  }
  
  .button:active {
    background-color: #003f7f; /* Even darker blue on click */
  }
  
  .button:disabled {
    background-color: #949494; /* Gray for disabled state */
    color: #d9d9d9;
    cursor: not-allowed;
  }

/*   .button-add {
    background-color: #28a745;
  }
  
  .button-add:hover {
    background-color: #218838;
  } */
  
/*   .button-remove {
    background-color: #dc3545; 
  }
  
  .button-remove:hover {
    background-color: #c82333;
  }
*/
  
  .button-calculate {
    background-color: var(--button-ready-color); 
    color: rgb(255, 255, 255);
  }

  .button-calculate.inactive {
    background-color: #d6d6d6; /* Gray background */
    color: #9e9e9e;
    cursor: not-allowed; /* Show the user it’s not functional */
  }
  
  .button-calculate:hover {
    background-color: var(--button-ready-color); 
  }
  

  .button-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .button-container h4 {
    margin-left: 15px; /* Space between the button and the text */
  }
  
  /*-------------------------DROP DOWN BOX -------------------------*/
  .dropdown-container {
    margin-top: 0px;
  }
  
  .dropdown-container select {
    padding: 5px;
    font-size: 14px;
    margin: 10px 0;
    background-color: #938D8A; /* Dark background color */
    color: white; /* White text color */
    border: 1px solid #ccc; /* Optional: Change the border color */
    border-radius: 4px; /* Add rounded corners */
    outline: none;
  }

  .dropdown-container select option {
    background-color: #938D8A; /* Dark background for the options */
    color: white; /* White text color */
  }
  
  /*--------------------------INPUT BOX--------------------------------*/
  .dashboard-container input[type="number"] {
    padding: 10px;
    font-size: 14px;
    width: 100px; /* Adjust width as necessary */
    margin-top: 10px;
  }

  /*--------------------------UNIT DETAILS--------------------------------*/

  /* Container for the IDU Units */
  /* Ensure the parent container takes full width */
  .parent-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .unit-details-wrapper {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: flex-start; /* Left-align the units */
    /*align-items: flex-start;*/
    gap: 20px; /* Add some space between units */
    width: 100%;
    max-width: 100%; /* Ensure no maximum width constraints */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }
  .unit-details {
    background-color: #938D8A;
    padding: 1px 15px 17px 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    width: 319px; /* Fixed width */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  }
  
  .unit-details h2 {
    color: #f1f1f1;
    text-align: center; /* Center the header */
    margin-top: 10px; /* Remove extra space above the header */
    margin-bottom: 10px;
  }
  
  .unit-details label {
    display: inline-block;
    width: 150px; /* Fixed width for better alignment */
    color: #f1f1f1;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 20px; /* Add line-height to match input height */
  }
  
  .unit-details input {
    height: 20px; /* Set consistent height */
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #ffffff; /* Light gray background */
    color: rgb(0, 0, 0); /* White text */
    width: 100px; /* Adjust width as needed */
    margin-bottom: 0px;
  }  
  
  .unit-details select {
    height: 30px; /* Set consistent height */
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    color: rgb(0, 0, 0); /* White text */
    width: 117px; /* Adjust width as needed */
    margin-bottom: 0px;
    margin-top: 3px;
  }

    /* Style for the default placeholder option */
  .unit-details select option:disabled {
    color: gray; /* Change the color to gray or any other color */
    font-style: italic; /* Italic style for the default option */
  }

  /* Ensure when the default option is selected, it retains the placeholder style */
  .unit-details select:invalid {
    color: gray; /* Same color as your disabled placeholder */
  }
  
  /* Remove the spinner (up/down arrows) for number inputs */
  .unit-details input[type="number"] {
    -moz-appearance: textfield; /* Remove in Firefox */
    appearance: textfield; /* Remove in Chrome, Safari, and Edge */
    height: 18px; /* Set same height as text inputs */
    padding: 4px 8px; /* Adjust padding to match */
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100px; /* Adjust width as needed */
    margin-bottom: 0px;
  }

  .unit-details input[type="number"]::-webkit-outer-spin-button,
  .unit-details input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Remove in WebKit browsers */
    margin: 0;
  }
  
  .unit-details input:focus, 
  .unit-details select:focus {
    outline: none;
    border-color: #3F8EFC; /* Add a focus color */
  }
  
  @media (max-width: 768px) {
    /* Mobile view */
    .unit-details label {
      width: 100%;
      margin-bottom: 5px;}
    .unit-details {
      flex: 1 1 100%; /* Take full width on mobile */
      max-width: 100%;
    }
  
    .unit-details input, 
    .unit-details select {
      width: 100%; /* Make inputs take full width */
    }
  }

  /* Style for the placeholder to be dark gray and italic */
  .input::placeholder {
    color: rgb(118, 118, 118); /* Dark gray */
    font-size: 14px; /* Match input text size */
    font-style: italic; /* Make placeholder text italic */
  }

  /* Style for the default placeholder option */
  .select-input:invalid {
    color: #ccc; /* Dark gray for the placeholder */
    font-style: italic; /* Italic style for the placeholder */
  }

  /* Ensure selected options are normal */
  .select-input option {
    font-style: normal; /* Reset to normal when an option is selected */
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the checkbox and label horizontally */
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .checkbox-container input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 15%;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    transition: border-color 0.3s ease;
  }

  .checkbox-container input[type="checkbox"]:checked {
    border-color: #3F8EFC; /* Blue border when checked */
    background-color: #3F8EFC; /* Blue background when checked */
  }
  
  .checkbox-container input[type="checkbox"]:checked::after {
    content: '\2713'; /* Unicode checkmark */
    font-size: 16px;
    color: white; /* White checkmark */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%); /* Center checkmark */
  }
  
  .checkbox-container input[type="checkbox"]:disabled {
    border-color: #aaaaaa; /* Gray out when disabled */
    background-color: #f0f0f0; /* Light background for disabled */
    cursor: not-allowed;
  }

  .checkbox-container input[type="checkbox"]:disabled + label {
    color: hwb(0 3% 8%); /* Gray out the label when disabled */
  }
  
  
  .checkbox-container input[type="checkbox"]:disabled::after {
    color: #aaaaaa; /* Gray out checkmark when disabled */
  }

  
/*------------------------------------ Info Icon styling ------------------------------------*/
.info-icon {
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
  color: #3F8EFC; /* Icon color */
  
  /* Circle styling */
  background-color: #fefefe; /* Background color */
  width: 15px; /* Adjust size as needed */
  height: 15px;
  border-radius: 50%; /* Makes it a perfect circle */
  
  display: inline-flex; /* Centers the icon inside */
  align-items: center;
  justify-content: center;
  
  /* Optional shadow */
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow, optional */
}

/*------------------------------------- Modal styling -----------------------------------*/
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



/*------------------------------------- Progress Bar styling -----------------------------------*/
/* Container for the progress bar */
.progress-bar-container {
  height: 5px;
  width: 100%; /* Full width of the container */
  background-color: #646464; /* Light gray background */
  border-radius: 5px;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure full width accounts for padding/border */
}

/* Progress bar itself */
.progress-bar {
  height: 100%;
  border-radius: 5px;
  background-image: repeating-linear-gradient(
    45deg,
    #3F8EFC 0%,
    #3F8EFC 25%,
    #f0f0f0 25%,
    #f0f0f0 50%
  );
  background-size: 20px 20px; /* Adjust stripe size */
  transition: width 0.3s ease; /* Smooth transition as progress increases */
}

/* When the progress is made, apply the solid color */
.progress-bar.filled {
  background-image: none;
  background-color: #3F8EFC;
}

/*-------------------------------Charge Inputs-----------------------------------------*/
.charge-inputs-container input {
  height: 18px; /* Same height as the unit details number inputs */
  padding: 4px 8px; /* Consistent padding */
  font-size: 14px;
  border-radius: 4px; /* Same rounded corners */
  border: 1px solid #ccc; /* Consistent border */
  background-color: #938D8A; /* Ensure the same background color */
  width: 100px; /* Same width as unit detail inputs */
  height: 10px; /* Set same height as text inputs */
  margin-bottom: 10px;
  color:#ffffffcb;
  
  /* Remove spinner arrows (same as the Unit Details section) */
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Chrome, Safari, Edge */
}

.charge-inputs-container input[type="number"]::-webkit-outer-spin-button,
.charge-inputs-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Remove in WebKit browsers */
  margin: 0;
}

.charge-inputs-container input:focus {
  outline: none;
  border-color: #3F8EFC; /* Same focus color as other inputs */
}

/*-------------------------------------System Type Cards-------------------------------------*/
/* Flexbox container */
.system-type-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: left;
  padding: 20px;
}

/* Individual system card styling */
.system-card {
  position: relative;
  background-color: #938D8A;
  border: 2px solid transparent;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
  flex: 1 1 200px;
  max-width: 300px;
  border-width: 4px;
  color: #000000; /* Set the font color for text inside the card */
}

.system-card:hover {
  background-color: #3498db71; /* Light background on hover */
}

.system-card.selected {
  border-color: #3F8EFC; /* Blue border when selected */
  background-color: #3498db71; /* Light background on hover */
  border-width: 4px;
}

/* Placeholder image styling */
.placeholder-image {
  width: 100px;
  height: 100px;
  background-color: #d3d3d3;
  margin: 0 auto 20px;
}

/* Description text */
.system-card p {
  font-size: 14px;
  color: #000000;
  margin: 10px 0;
}

/* Checkmark container */
.checkmark-container {
  position: absolute; /* Position it absolutely within the card */
  top: 10px;  /* Control vertical padding */
  left: 10px; /* Control horizontal padding */
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  Checkmark styling */
.checkmark-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: inline-block;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* When card is selected, show checkmark and blue border */
.system-card.selected .checkmark-icon {
  background-color: #3F8EFC; /* Blue color same as progress bar */
  border-color: #3F8EFC;
}

.system-card.selected {
  border-color: #3F8EFC; /* Blue border on selection */
}

.system-card.selected .checkmark-icon::after {
  content: '\2713'; /* Checkmark symbol */
  font-size: 16px;   /* Increase the font size for a larger checkmark */
  font-weight: bold; /* Make the checkmark bold */
  color: white;
  display: block;
  text-align: center;
  line-height: 20px;
  border-color: #3F8EFC; /* Blue border */
}

/* --------------------------Results Container Styling--------------------------------- */
.results-container {
  background-color: #3498db71; /* Blue background */
  color: white;
  border: 2px solid white; /* White border */
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px; /* Add some space between the button and the results */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
}

.results-container h1 {
  margin-bottom: 10px;
  margin-top: 0; /* Remove extra margin */
}

.results-container h2 {
  margin-bottom: 0px;
  margin-top: 0; /* Remove extra margin */
}

.results-container h3 {
  margin-bottom: 0px;
  margin-top: 0; /* Remove extra margin */
  text-decoration: underline;
}

.results-container h4 {
  margin-bottom: 0px;
  margin-top: 0; /* Remove extra margin */
  text-decoration: underline;
}

.results-container p {
  margin: 5px 0;
  margin-left: 10px;
  font-size: 16px;
  color: #ffffffcb;
}

/* Compliance Result */
.compliance-result {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Icon base styling */
.compliance-icon {
  width: 18px; /* Adjust based on the size of the h1 text */
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  text-align: center;
}

/* Checkmark styling for success */
  .checkmark {
    background-color: green;
    border: 2px solid white;
    position: relative;
  }

  .checkmark::after {
    content: '\2713'; /* Unicode checkmark symbol */
    color: white;
    font-size: 12px; /* Adjust to match the size of the h1 text */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

  /* Crossmark styling for failure */
  .crossmark {
    background-color: red;
    border: 2px solid white;
    position: relative;
  }

  .crossmark::after {
    content: '\2715'; /* Unicode X symbol */
    color: white;
    font-size: 12px; /* Adjust to match the size of the h1 text */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

/* Question mark styling for cases needing more calculations */
  .questionmark {
    background-color: yellow;
    border: 2px solid rgb(0, 0, 0);
    position: relative;
  }

  .questionmark::after {
    content: '\003F'; /* Unicode question mark */
    color: rgb(0, 0, 0);
    font-size: 12px; /* Adjust to match the size of the h1 text */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

  .results-grid {
    display: grid;
    grid-template-columns: 150px 1fr; /* Fixed width for labels and flexible width for values */
    column-gap: 10px; /* Space between label and value */
    row-gap: 5px; /* Space between rows */
    margin-bottom: 15px;
  }
  
  .results-grid .label {
    color: white; /* Label text color */
  }
  
  .results-grid .value {
    color: white; /* Value text color */
    text-align: left;
  }
  
  .results-grid h2 {
    grid-column: span 2; /* Make headers span the entire grid width */
    margin-top: 15px; /* Space before the headers */
  }

  .results-grid h4 {
    grid-column: span 2; /* Make headers span the entire grid width */
    margin-top: 15px; /* Space before the headers */
  }

  /* ----------------------------Unit Resuts Cards ------------------------------------------*/
  .unit-compliance-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
  }

  .unit-compliance-card {
      background-color: #3F8EFC;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 15px;
      width: 200px;
      text-align: center;
  }

  .unit-compliance-card h3 {
      margin-bottom: 10px;
  }

  .unit-compliance-card p {
      margin: 5px 0;
  }

  .compliance-icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      text-align: center;
  }

  .checkmark {
      background-color: green;
      border: 2px solid white;
  }

  .crossmark {
      background-color: red;
      border: 2px solid white;
  }

  
  





  
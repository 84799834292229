@import './variables.css';

.site-header {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: var(--dashboard-bg);
  z-index: 10;
  transition: height 0.2s ease-in-out, left 0.2s ease-in-out, width 0.2s ease-in-out;
}

.site-header.compressed {
  height: 60px;
}

.site-header.compressed .logo img {
  width: 200px;
}

.site-header.compressed + body {
  padding-top: 0px; /* Adjust padding when the compressed header is used */
}

.app.sidebar-collapsed + .site-header {
  left: 60px;
  width: calc(100% - 60px);
}

.app.sidebar-open + .site-header {
  padding-left: 250px; /* Match expanded sidebar width */
}

.logo {
  flex: 0 0 350px; /* Fix logo width */
  height: 120px; /* Fixed height */
  display: flex;
  align-items: center;
  padding-left: 60px;
}

.logo img {
  width: 100%; /* Logo width initially matches container */
  height: auto; /* Maintain aspect ratio */
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out; /* Smooth transition when resizing */
}

.header-content {
  display: flex;
  align-items: center;
  height: 60px; /* Reduce height to match compressed header */
  width: 100%; /* Use full width of the header */
  justify-content: space-between; /* Space out logo, search bar, and actions */
  gap: 20px; /* Add spacing */
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-shrink: 0; /* Prevent shrinking */
}

.search-box {
  flex: 1; /* Allow search bar to grow and shrink */
  display: flex;
  justify-content: center; /* Keep content centered */
  min-width: 200px; /* Prevent search bar from collapsing */
  max-width: 500px; /* Limit max width */
}

.search-box input {
  width: 100%; /* Take up all space within the search box */
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 20px;
  outline: none;
}

.search-box button {
  background: transparent;
  color: var(--header-button-color);
  border: none;
  font-size: 1.2em; /* Increase the size of the icon */
  font-weight: bold; /* Make the icon bolder */
  padding: 6px;
  cursor: pointer;
  /*border: 1px solid #000; /* Temporary border for visualization */
}

.language-switcher button {
  background: none;
  border: none;
  color: var(--header-button-color);
  cursor: pointer;
  font-weight: bold;
  font-size: 1.7em;
  /*border: 1px solid #000; /* Temporary border for visualization */
}

/* .login-button, .contact-button {
  text-decoration: none;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  white-space: nowrap; 
  transition: background-color 0.3s ease, color 0.3s ease;
}

.login-button {
  color: var(--header-button-color);
  border: 1px solid var(--header-button-color);
  background: none;
} */

.login-button, .contact-button {
  text-decoration: none;
  padding: 4px 16px;
  border-radius: 20px;
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-flex; /* Ensures both <a> and <button> behave the same */
  align-items: center; /* Centers the text and icon */
  justify-content: center; /* Keeps text centered */
  height: 28px; /* Ensures consistent height */
  font-size: 14px; /* Makes text consistent */
  gap: 4px;
}

/* Ensures both <a> and <button> have identical border and background */
.login-button {
  color: var(--header-button-color);
  border: 1px solid var(--header-button-color);
  background: none;
  cursor: pointer; /* Ensures pointer style for buttons */
}

/* Fix default button styling */
.login-button:not([href]) { 
  appearance: none; /* Removes default button styles */
  border: 1px solid var(--header-button-color);
  background: none;
  padding: 4px 16px;
}

/* Hover effects */
.login-button:hover {
  background-color: var(--header-button-hover-bg, rgba(0, 0, 0, 0.1));
}


.contact-button {
  background-color: #0144a2;
  color: white;
  border: none;
}

.login-button:hover, .contact-button:hover {
  opacity: 0.8;
}

.mobile-icons {
  display: none;
}

body {
  margin: 0;
  padding-top: 100px; /* Offset for fixed header height */
}

@media (max-width: 768px) {

  .header-content {
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #000; /* Temporary border for visualization */
  }

  .header-actions {
    gap: 10px;
    flex-direction: column; /* Stack items vertically on small screens for better alignment */
    align-items: flex-start;
    margin-top: 10px;
    border: 1px solid #000; /* Temporary border for visualization */
  }

  .logo {
    width: 350px; /* Container for the logo with fixed width */
    height: 120px; /* Fixed height to prevent movement */
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .login-button, .contact-button {
    display: none; /* Hide for smaller screens */
  }

  .search-box, .language-switcher {
    margin-top: 10px; /* Provide some space between stacked items */
    border: 1px solid #000; /* Temporary border for visualization */
  }

  .search-box input {
    display: none;
  }

  .mobile-icons {
    display: flex;
    font-size: 1.7rem;
    color: var(--secondary-color);
  }
}
